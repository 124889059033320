import { createAsyncThunk } from '@reduxjs/toolkit';
import BaseAPI from 'services/BaseAPI';

const baseAPI = new BaseAPI();

const createApiThunk = (type, endpoint) => {
  return createAsyncThunk(type, async ({params = {}, urlParams={}}) => {
    const url = typeof endpoint === 'function' ? endpoint({...urlParams}) : endpoint;
    const response =  await baseAPI.makeRequest({
      url,
      params
    });
    return response;
  });
};

export default createApiThunk;