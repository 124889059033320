import axios from "axios";
import { toast } from "react-toastify";
import { getEnvironment } from "utils/environment";
import { getToken } from "utils/storage";

const { BASE_URL } = getEnvironment();

class BaseAPI {
  // Handle API Request
  async makeRequest(req, showError = false, contentType = "application/json") {
    const { url, method, data, responseType } = req;

    const axiosObj = {
      url: BASE_URL + url,
      method: method,
      data: data,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${getToken()}`,
      },
    };

    responseType && (axiosObj.responseType = responseType);

    try {
      const res = await axios(axiosObj);
      return res.data;
    } catch (error) {
      const {status, statusCode} = error;
      console.error("API Error:", error);
      if(status === 401 || statusCode === 401) {
        window.location.href = "/logout";
      }
      if (showError) {
        this.handleError(error);
      }
      return error?.response?.data;
    }
  }

  // Handle API Errors
  async handleError(error) {
    const { response } = error;

    if (response) {
      const { statusCode, message } = response.data || {};

      const errorMessages = {
        401: "Unauthorized",
        412: "Validation Error",
        400: "Bad Request",
        404: "Not Found",
        428: "Step Error",
        500: "Internal Server Error",
      };

      switch (statusCode) {
        case 401:
          window.location.href = "/logout";
          break;

        default:
          this.showError(
            message || errorMessages[statusCode] || "An error occurred."
          );
      }
    } else {
      toast.error(error.message || "An unexpected error occurred.");

      if (error?.code === "ERR_CANCELED") window.location.href = "/logout";
      console.error("Request setup error:", error);
    }
  }

  // Display Error Notification
  showError(text) {
    toast.error(text);
  }

  // Get CustomerID
  getCustomerID() {
    return localStorage.getItem("customerID");
  }
}

export default BaseAPI;
