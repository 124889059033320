import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Dashboard = lazy(() => import("pages/Dashboard"));

// CRMManagement
const Users = lazy(() => import("pages/CRMManagement/Users"));
const UserListAdd = lazy(() => import("pages/CRMManagement/UserListAdd"));
const UserListUpdate = lazy(() => import("pages/CRMManagement/UserListUpdate"));
const UserListAccess = lazy(() => import("pages/CRMManagement/UserListAccess"));
const HavePermission = lazy(() => import("pages/CRMManagement/HavePermission"));
const Logins = lazy(() => import("pages/CRMManagement/Logins"));
const IPWhiteListing = lazy(() => import("pages/CRMManagement/IPWhiteListing"));
const PermissionsList = lazy(() => import("pages/CRMManagement/PermissionsList"));
const RolesList = lazy(() => import("pages/CRMManagement/RolesList"));

// App Management
const AutoDisbursalStatus = lazy(() => import("pages/AppFunctionManagement/AutoDisbursalStatus"));
const Holiday = lazy(() => import("pages/AppFunctionManagement/HolidayList"));
const RepaymentGatewayType = lazy(() => import("pages/AppFunctionManagement/RepaymentGatewayType"));

// Lead Actions
const RepaymentDateManager = lazy(() => import("pages/LeadActions/RepaymentDateManager"));
const NameMismatchManager = lazy(() => import("pages/LeadActions/NameMismatchManager"));
const PaymentModeManager = lazy(() => import("pages/LeadActions/PaymentModeManager"));

// Collection
const PendingCollection = lazy(() => import("pages/Collection/PendingCollection"));
const PaydayPaymentPendingEmi = lazy(() => import("pages/Collection/PaydayPaymentPendingEmi"));
const BulkEmandate = lazy(() => import("pages/Collection/BulkEmandate/BulkEmandate"));
const Closed = lazy(() => import("pages/Collection/Closed"));
const DNDCustomer = lazy(() => import("pages/Collection/DNDCustomer"));
const PartPaid = lazy(() => import("pages/Collection/PartPaid"));
const Settled = lazy(() => import("pages/Collection/Settled"));
const WaivedOff = lazy(() => import("pages/Collection/WaivedOff"));
const AddNotRequired = lazy(() => import("pages/Collection/AddNotRequired"));

{/* Collection Manager */ }
const CollectionManager = lazy(() => import("pages/CollectionManager/collectionManager"));
const ApprovalPending = lazy(() => import("pages/CollectionManager/ApprovalPending"));
const Approved = lazy(() => import("pages/CollectionManager/Approved"));
const OnlinePayments = lazy(() => import("pages/CollectionManager/OnlinePayments"));
const Rejected = lazy(() => import("pages/CollectionManager/Rejected"));

// Reports
const Report = lazy(() => import("pages/Reports/report"));
const AllLeadReports = lazy(() => import("pages/Reports/allLeadReports"));
const DateWiseCollectionReport = lazy(() => import("pages/Reports/dateWiseCollectionReport"));
const DateWiseLeadReport = lazy(() => import("pages/Reports/dateWiseLeadReport"));
const PayDayPendingReport = lazy(() => import("pages/Reports/payDayPendingReport"));
const CustomerFeedback = lazy(() => import("pages/Reports/customerFeedback"));
const RefundReport = lazy(() => import("pages/Reports/refundReport"));
const AppIssueListReport = lazy(() => import("pages/Reports/appIssueListReport"));
const DisbursedDataReport = lazy(() => import("pages/Reports/disbursedDataReport"));
const CollectionDataReport = lazy(() => import("pages/Reports/collectionDataReport"));
const CollectionEmiDataReport = lazy(() => import("pages/Reports/collectionEmiDataReport"));
const LandingPartnerLeads = lazy(() => import("pages/Reports/landingPartnerLeads"));
const QuickReportsWrapper = lazy(() => import("pages/Reports/quickReportsWrapper"));
const QuickReports = lazy(() => import("pages/Reports/quickReports"));

// Customers
const Customers = lazy(() => import("pages/Customers"));

// Leads
const AllLeads = lazy(() => import("pages/Leads/AllLeads"));
const CreditLeads = lazy(() => import("pages/Leads/CreditLeads"));
const HotLeads = lazy(() => import("pages/Leads/HotLeads"));
const SanctionLeads = lazy(() => import("pages/Leads/SanctionLeads"));

// Disbursal
const BankUpdate = lazy(() => import("pages/Disbursal/BankUpdate"));
const BankUpdateRejected = lazy(() => import("pages/Disbursal/BankUpdateRejected"));
const Disbursed = lazy(() => import("pages/Disbursal/Disbursed"));
const ManualDisbursal = lazy(() => import("pages/Disbursal/ManualDisbursal"));

// Collection Setting
const SettlementReport = lazy(() => import("pages/CollectionSetting/SettlementReport"));

// Logs
const APILogs = lazy(() => import("pages/Logs/APILogs"));
const AppInstallations = lazy(() => import("pages/Logs/AppInstallations"));
const ChatLogs = lazy(() => import("pages/Logs/ChatLogs"));
const DialerLogs = lazy(() => import("pages/Logs/DialerLogs"));
const KaleyraLogs = lazy(() => import("pages/Logs/KaleyraLogs"));
const RazorpayLogs = lazy(() => import("pages/Logs/RazorpayLogs"));
const SendInBlueLogs = lazy(() => import("pages/Logs/SendInBlueLogs"));
const WhatsappLogs = lazy(() => import("pages/Logs/WhatsappLogs"));

// Black List
const BlacklistCustomers = lazy(() => import("pages/Blacklisted/BlacklistCustomers"));
const BlacklistPancard = lazy(() => import("pages/Blacklisted/BlacklistPancard"));

// Customer Profile
const CustomerProfile = lazy(() => import("pages/CustomerProfile"));

// Logout
const Logout = lazy(() => import("pages/Authentication/Logout"));

// Error404
const Error404 = lazy(() => import("components/errors/Error404"));


export default function LazyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />

      {/* crm management */}
      <Route path="/crm-management/users" element={<Users />} />
      <Route path="/crm-management/user-list-access/:userID" element={<UserListAccess />} />
      <Route path="/crm-management/user-list-update/:userID" element={<UserListUpdate />} />
      <Route path="/crm-management/user-list-add" element={<UserListAdd />} />
      <Route path="/crm-management/logins" element={<Logins />} />
      <Route path="/crm-management/ipwhite-listing" element={<IPWhiteListing />} />
      <Route path="/crm-management/roles-list" element={<RolesList />} />
      <Route path="/crm-management/roles-list/:role_id" element={<HavePermission />} />
      <Route path="/crm-management/permission-list" element={<PermissionsList />} />

      {/* App Management */}
      <Route path="/repaydate_holiday" element={<Holiday />} />
      <Route path="/auto_disb_status" element={<AutoDisbursalStatus />} />
      <Route path="/repayment_gateway_type" element={<RepaymentGatewayType />} />

      {/* Lead Actions */}
      <Route path="/lead-actions/repayment-date-manager" element={<RepaymentDateManager />} />
      <Route path="/lead-actions/name-mismatch-manager" element={<NameMismatchManager />} />
      <Route path="/lead-actions/payment-mode-manager" element={<PaymentModeManager />} />

      {/* Collection */}
      <Route path="/collection/pending-collection" element={<PendingCollection />} />
      <Route path="/collection/payday-payment-pending-emi" element={<PaydayPaymentPendingEmi />} />
      <Route path="/collection/add-not-required" element={<AddNotRequired />} />
      <Route path="/collection/bulk-emandate" element={<BulkEmandate />} />
      <Route path="/collection/closed" element={<Closed />} />
      <Route path="/collection/dnd-customer" element={<DNDCustomer />} />
      <Route path="/collection/part-paid" element={<PartPaid />} />
      <Route path="/collection/settled" element={<Settled />} />
      <Route path="/collection/waived-off" element={<WaivedOff />} />

      {/* Collection Manager */}
      <Route path="/collection-manager" element={<CollectionManager />}>
        <Route index={true} path="approval-pending" element={<ApprovalPending />} />
        <Route path="approved" element={<Approved />} />
        <Route path="online-payment" element={<OnlinePayments />} />
        <Route path="rejected" element={<Rejected />} />
      </Route>

      {/* Reports */}

      <Route path="/report" element={<Report />}>
        <Route path="all_lead_report" element={<AllLeadReports />} />
        <Route path="quick-reports" element={<QuickReportsWrapper />}>
          <Route path=":procedure" element={<QuickReports />} />
        </Route>
        <Route path="date_wise_collection_report" element={<DateWiseCollectionReport />} />
        <Route path="date_wise_lead_report" element={<DateWiseLeadReport />} />
        <Route path="date_wise_pending_payment_report" element={<PayDayPendingReport />} />
        <Route path="customer_feedback_report" element={<CustomerFeedback />} />
        <Route path="refund_report" element={<RefundReport />} />
        <Route path="app_issue_list_report" element={<AppIssueListReport />} />
        <Route path="disbursed_data_report" element={<DisbursedDataReport />} />
        <Route path="collection_data_report" element={<CollectionDataReport />} />
        <Route path="collection_emi_data_report" element={<CollectionEmiDataReport />} />
        <Route path="landing_partner_leads_report" element={<LandingPartnerLeads />} />
      </Route>

      {/* Customers */}
      <Route path="/customers" element={<Customers />} />

      {/* Leads */}
      <Route path="/all-leads" element={<AllLeads />} />
      <Route path="/credit-leads" element={<CreditLeads />} />
      <Route path="/hot-leads" element={<HotLeads />} />
      <Route path="/sanction-leads" element={<SanctionLeads />} />

      {/* Disbursal */}
      <Route path="/disbursal/bank_update" element={<BankUpdate />} />
      <Route path="/disbursal/disbursed" element={<Disbursed />} />
      <Route path="/disbursal/bank_update_rejected" element={<BankUpdateRejected />} />
      <Route path="/manual_disbursal" element={<ManualDisbursal />} />

      {/* Collection Setting */}
      <Route path="/settlement-report" element={<SettlementReport />} />

      {/* Logs */}
      <Route path="/logs/api-logs" element={<APILogs />} />
      <Route path="/logs/app-installations" element={<AppInstallations />} />
      <Route path="/logs/chat-logs" element={<ChatLogs />} />
      <Route path="/logs/dialer-logs" element={<DialerLogs />} />
      <Route path="/logs/kaleyra-logs" element={<KaleyraLogs />} />
      <Route path="/logs/razorpay-logs" element={<RazorpayLogs />} />
      <Route path="/logs/send-inblue-logs" element={<SendInBlueLogs />} />
      <Route path="/logs/whatsapp-logs" element={<WhatsappLogs />} />

      {/* Block Listed */}
      <Route path="/blacklist/customers" element={<BlacklistCustomers />} />
      <Route path="/blacklist/pancard" element={<BlacklistPancard />} />

      {/* Customer Profile */}
      <Route path="/customer-lead-profile/:id" element={<CustomerProfile />} />

      {/* Logout */}
      <Route path="/logout" element={<Logout />} />

      {/* Logout */}
      <Route path="*" element={<Error404 />} />

    </Routes>
  )
}