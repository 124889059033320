import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage

import paginationReducer from "./slices/paginationSlice";
import appReducer from "./slices/appSlice";
import userReducer from "./slices/userSlice";
import navbarSlice from "./slices/navbarSlice";

import { 
  creditDetailReducer, 
  loggedInUserReducer, 
  loanDetailsReducer 
} from "./slices/apiSlices";

// Persist config
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"], // You can choose which reducer to persist (e.g., 'user')
};

// Combine reducers
const rootReducer = combineReducers({
  app: appReducer,
  pagination: paginationReducer,
  user: userReducer,
  navbar: navbarSlice,
  loanDetails: loanDetailsReducer,
  creditDetail: creditDetailReducer,
  loggedInUser: loggedInUserReducer
});

// Persist the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disables the warning about non-serializable values in the state (needed for redux-persist)
    }),
});

// Create a persistor
export const persistor = persistStore(store);
