import React, { useEffect } from "react";
import Layout from "./layout/Layout";
import Cookies from "js-cookie";
import { storeToken } from "./utils/storage";
import AppRouter from "routes/AppRouter";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

export default function App() {
  const { isLoggedIn } = useSelector((state) => state.user);

  // const token = Cookies.get("jwt_token");
  const token = "";

  // useEffect(() => {
  //   const hasReloaded = Cookies.get("hasReloaded");
  //   if (!hasReloaded) {
  //     Cookies.set("hasReloaded", "true", { expires: 1 });
  //     window.location.reload();
  //   }
  //   storeToken(token);
  // }, [token]);

  return (
    <>
      {!isLoggedIn ? <AppRouter /> : <Layout />}
      <ToastContainer theme="colored" />
    </>
  );
}
