import BaseAPI from "./BaseAPI";

class AuthAPI extends BaseAPI {
  // Login API
  async loginAPI(values) {
    const req = {
      url: "api-crm/login/crm",
      method: "POST",
      data: {
        userName: values.name,
        password: values.password,
      },
    };
    return await this.makeRequest(req);
  }

  // Verify API
  async verifyOTPAPI(values, userID) {
    const req = {
      url: "api-crm/login/verfication/otp",
      method: "POST",
      data: {
        otp: values.otp,
        userID: userID,
      },
    };
    return await this.makeRequest(req);
  }

  // Forgot Password API
  async forgotPasswordAPI(values) {
    const req = {
      url: "api-crm/login/forgot-password",
      method: "POST",
      data: {
        userName: values.userName,
      },
    };
    return await this.makeRequest(req);
  }

  // Change Password API
  async changePasswordAPI(values, userID) {
    const req = {
      url: "api-crm/login/update-password",
      method: "POST",
      data: {
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
        otp: values.otp,
        userID: userID,
      },
    };
    return await this.makeRequest(req);
  }

  // Auto Login API
  async autoLoginAPI(params) {
    const req = {
      url: "api-crm/login/user-login-validation",
      method: "POST",
      data: {
        code: params,
      },
    };
    return await this.makeRequest(req);
  }
}

export default AuthAPI;
