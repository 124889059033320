import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthAPI from "services/AuthAPI";
import { storeToken } from "../../utils/storage";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updatePermissions, updateRoleName, updateUserLogin } from "../../redux/slices/userSlice";
import { Loader } from "components";

export default function AutoLogin() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = searchParams.get("code");
  const dispatch = useDispatch();

  const apiInstance = new AuthAPI();

  useEffect(() => {
    handleAutoLogin();
  }, []);

  const handleAutoLogin = async () => {
    const res = await apiInstance.autoLoginAPI(params);

    if (res?.statusCode === 200) {
      storeToken(res.data.token);
      toast.success(res.message);
      dispatch(updateUserLogin(true));
      dispatch(updatePermissions(res.data.permissions));
      dispatch(updateRoleName(res.data.roleName));
    } else {
      toast.error(res.message);
    }

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return <Loader />;
}
