import React, { useEffect } from "react";
import {
  Header,
  Heading,
  HeadingGroup,
  Panel,
  Text,
  Input,
  Grid,
  Row,
  Col,
  Button,
} from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";
import { storeToken } from "../../utils/storage";
import styles from "./Auth.module.scss";

import { Formik } from "formik";
import { ErrorMessage } from "components";
import { otpVerifyValidation } from "validation/AuthValidation";
import { toast } from "react-toastify";
import AuthAPI from "services/AuthAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePermissions,
  updateRoleName,
  updateUserLogin,
} from "../../redux/slices/userSlice";

export default function VerifyOTP() {
  const navigate = useNavigate();
  const location = useLocation();

  const userID = location.state?.userID || "";

  const apiInstance = new AuthAPI();

  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // console.log("isLoggedIn-------------", isLoggedIn);

  useEffect(() => {
    if (!location.state?.userID) {
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <>
      <Header className={styles.top__header}>ॐ नमो भगवते वासुदेवाय नमः</Header>
      <div className={styles.container}>
        <div className={styles.login__card}>
          <div className={styles.logo}>
            <img src="/images/logo2.png" />
          </div>
          <Panel className={styles.panel} shaded>
            <HeadingGroup className="text-center">
              <Heading level={4} className="mb-5">
                Verify OTP 👋
              </Heading>
              <Text muted>
                We have sent the verification code to your email address
              </Text>
            </HeadingGroup>

            <div>
              <Formik
                initialValues={{
                  otp: "",
                }}
                validationSchema={otpVerifyValidation}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setTimeout(async () => {
                    const res = await apiInstance.verifyOTPAPI(values, userID);

                    if (res?.statusCode === 200) {
                      storeToken(res.data.token);
                      toast.success(res.message);
                      dispatch(updateUserLogin(true));
                      dispatch(updatePermissions(res.data.permissions));
                      dispatch(updateRoleName(res.data.roleName));
                      setTimeout(() => {
                        window.location.href = "/";
                      }, 500);
                    } else {
                      toast.error(res.message);
                    }
                    setSubmitting(false);
                  }, 500);
                }}
              >
                {({
                  errors,
                  values,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid fluid>
                      <Row>
                        <Col sm={24}>
                          <div className="form-group">
                            <label className="form-label">Enter OTP</label>
                            <Input
                              placeholder="Enter Your OTP"
                              value={values.otp || ""}
                              type="number"
                              onChange={(value) => {
                                setFieldValue("otp", value);
                              }}
                            />
                            <ErrorMessage
                              touched={touched.otp}
                              error={errors.otp}
                            />
                          </div>
                        </Col>

                        <Col sm={24}>
                          <Button
                            block
                            type="submit"
                            appearance="primary"
                            loading={isSubmitting}
                            className="mt-10"
                          >
                            Verify Now!
                          </Button>
                        </Col>
                      </Row>
                    </Grid>
                  </form>
                )}
              </Formik>
            </div>
          </Panel>
        </div>
      </div>
    </>
  );
}
