import BaseAPI from "./BaseAPI";

class CommonAPI extends BaseAPI {
  constructor() {
    super();
  }

  // Filter API
  async filterAPI(pageName) {
    const req = {
      url: "api-crm/filter",
      method: "POST",
      data: { pageName },
    };
    return await this.makeRequest(req);
  }

  // Blacklisted API
  async blacklistedAPI(pageName) {
    const queryParams = new URLSearchParams(window.location.search);
    const req = {
      url: `api-crm/blacklist/blacklist_user?pageName=${pageName}&${queryParams.toString()}`,
      method: "GET",
      data: { pageName },
    };

    return await this.makeRequest(req);
  }

  async getUserPermissionAPI() {
    const req = {
      url: "api-crm/user/getUserPermission",
      method: "GET",
    };
    return await this.makeRequest(req);
  }

  async fetchResourceUrlAPI(entityID, type='agreement') {
    const req = {
      url: `api-crm/doc/pdf-url?entityID=${entityID}&type=${type}`,
      method: "GET",
    };
    return await this.makeRequest(req);
  }


}

export default CommonAPI;
