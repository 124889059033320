import React from "react";
import { Route, Routes } from "react-router-dom";

// Pages
import Login from "pages/Authentication/Login";
import VerifyOTP from "pages/Authentication/VerifyOTP";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ChangePassword from "pages/Authentication/ChangePassword";
import Error404 from "components/errors/Error404";
import AutoLogin from "pages/Authentication/AutoLogin";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/verify-otp" element={<VerifyOTP />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/auto-login" element={<AutoLogin />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
