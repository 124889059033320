import React, { useEffect } from "react";
import {
  Header,
  Heading,
  HeadingGroup,
  Panel,
  Text,
  Input,
  Grid,
  Row,
  Col,
  Button,
} from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";
import { storeToken } from "../../utils/storage";
import styles from "./Auth.module.scss";

import { Formik } from "formik";
import { ErrorMessage } from "components";
import {
  forgotPasswordValidation,
  otpVerifyValidation,
} from "validation/AuthValidation";
import { toast } from "react-toastify";
import AuthAPI from "services/AuthAPI";
import { useDispatch, useSelector } from "react-redux";
import { updateUserLogin } from "../../redux/slices/userSlice";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const apiInstance = new AuthAPI();

  return (
    <>
      <Header className={styles.top__header}>ॐ नमो भगवते वासुदेवाय नमः</Header>
      <div className={styles.container}>
        <div className={styles.login__card}>
          <div className={styles.logo}>
            <img src="/images/logo2.png" />
          </div>
          <Panel className={styles.panel} shaded>
            <HeadingGroup className="text-center">
              <Heading level={4} className="mb-5">
                Forgot Password 👋
              </Heading>
              <Text muted>
                No worries, we will send you reset instructions.
              </Text>
            </HeadingGroup>

            <div>
              <Formik
                initialValues={{
                  userName: "",
                }}
                validationSchema={forgotPasswordValidation}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setTimeout(async () => {
                    // console.log("values----------", values);

                    const res = await apiInstance.forgotPasswordAPI(values);
                    if (res && res.statusCode === 200) {
                      toast.success(res.message);
                      navigate("/change-password", {
                        state: { userID: res.data.userID },
                      });
                    } else {
                      toast.error(res.message);
                    }

                    setSubmitting(false);
                  }, 500);
                }}
              >
                {({
                  errors,
                  values,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid fluid>
                      <Row>
                        <Col sm={24}>
                          <div className="form-group">
                            <label className="form-label">User Name</label>
                            <Input
                              placeholder="Enter User Name"
                              value={values.userName || ""}
                              onChange={(value) => {
                                setFieldValue("userName", value);
                              }}
                            />
                            <ErrorMessage
                              touched={touched.userName}
                              error={errors.userName}
                            />
                          </div>
                        </Col>

                        <Col sm={24}>
                          <Button
                            block
                            type="submit"
                            appearance="primary"
                            loading={isSubmitting}
                            className="mt-15"
                          >
                            Reset Password
                          </Button>
                        </Col>
                      </Row>
                    </Grid>
                  </form>
                )}
              </Formik>
            </div>
          </Panel>
        </div>
      </div>
    </>
  );
}
