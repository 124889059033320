import { createSlice } from '@reduxjs/toolkit';
import createApiThunk from '../middlewares/thunk';

// Helper function to generate thunks and reducers dynamically for any API
const createApiSlice = (name, endpoint) => {
  const fetchData = createApiThunk(`${name}/fetchData`, endpoint);
  const apiSlice = createSlice({
    name,
    initialState: {
      loading: false,
      data: [],
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchData.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchData.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(fetchData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });

  return { fetchData, reducer: apiSlice.reducer };
};

export default createApiSlice;