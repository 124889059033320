import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./layout.scss";
import { Container, Header, Sidebar, Sidenav, Content, IconButton, HStack, Stack } from "rsuite";
import Router from "../routes/Router";
import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Navbar } from "../components";
import useResetPagination from "../hooks/useResetPagination";
import useWindowSize from "hooks/useWindowSize";

export default function Layout(props) {
  const [expand, setExpand] = useState(true);
  const [openKeys, setOpenKeys] = useState("/");

  const { width, height } = useWindowSize();

  useEffect(() => {
    if (width > 992) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [width]);

  const windowHeight = window.innerHeight;

  useResetPagination();


  return (
    <>
      <Container>
        <Sidebar
          className="sidebar"
          style={{ display: "flex", flexDirection: "column" }}
          width={expand ? 256 : 56}
          collapsible
        >
          <Sidenav.Header>
            <Brand expand={expand} />
          </Sidenav.Header>

          <div className="sidebar__body" style={{ height: windowHeight }}>
            <Sidenav expanded={expand} defaultOpenKeys={["/"]} openKeys={[openKeys]} appearance="inverse">
              <Sidenav.Body>
                <Navbar openKeys={openKeys} setOpenKeys={setOpenKeys} />
              </Sidenav.Body>
            </Sidenav>
          </div>

          {/* <NavToggle expand={expand} onChange={() => setExpand(!expand)} /> */}
        </Sidebar>
        <Content style={{ marginLeft: expand ? 256 : 60, padding: 15 }}>
          <Header className="top__header">ॐ नमो भगवते वासुदेवाय नमः</Header>
          <Router />
        </Content>
      </Container>
    </>
  );
}

const NavToggle = ({ expand, onChange }) => {
  return (
    <Stack className="nav-toggle" justifyContent={expand ? "flex-end" : "center"}>
      <IconButton
        onClick={onChange}
        appearance="subtle"
        size="lg"
        icon={expand ? <MdKeyboardArrowLeft /> : <MdOutlineKeyboardArrowRight />}
      />
    </Stack>
  );
};

const Brand = ({ expand }) => {
  return (
    <HStack className="page-brand" spacing={12}>
      {/* <FaReact size={26} />
      {expand && <Text>Brand</Text>} */}
      <Link
        to={"/"}
        style={{
          padding: "20px 12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/images/logo.png" width="160" alt="" />
      </Link>
    </HStack>
  );
};
