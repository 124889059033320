// urls
export const DOWNLOAD_DATE_WISE_COLLECTION_REPORT_END_POINT = 'report/download-date-wise-collection-report/';
export const DOWNLOAD_DATE_WISE_LEAD_REPORT_END_POINT = 'report/download-date-wise-lead-report/';
export const DOWNLOAD_REFUND_REPORT_END_POINT = 'report/download-refund-report/';
export const DOWNLOAD_DISBURSAL_DATA_REPORT_END_POINT = 'report/download-disbursal-data-report/'
export const DOWNLOAD_COLLECTION_DATA_REPORT_END_POINT = 'report/download-collection-data-report/'
export const DOWNLOAD_COLLECTION_EMI_REPORT_END_POINT = 'report/download-collection-emi-report/';
export const DOWNLOAD_LANDING_PARTNER_REPORT_END_POINT = 'report/download-landing-partner-leads-report/';
export const DOWNLOAD_QUICK_REPORT_END_POINT = 'quick-reports/download';


export const INITIAL_PAGE = 1;
export const LIMIT = 20;

export const QUICK_REPORT_BASE_URL = 'report/quick-reports/'