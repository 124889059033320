import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  leadStatus: null,
  permissions: null, // permissions added
  roleName: "",
  leadType: "",
  productID: "",
  repayDate: "",
  salaryDate: "",
  customerProfileInfo:{},
};

export const userSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateUserLogin: (state, action) => {
      state.isLoggedIn = action.payload;
    },

    updateLeadStatus: (state, action) => {
      state.leadStatus = action.payload;
    },

    updatePermissions: (state, action) => {
      state.permissions = action.payload;
    },

    updateRoleName: (state, action) => {
      state.roleName = action.payload;
    },

    updateLeadType: (state, action) => {
      state.leadType = action.payload;
    },

    updateProductID: (state, action) => {
      state.productID = action.payload;
    },

    updateRepayDate: (state, action) => {
      state.repayDate = action.payload;
    },

    updateSalaryDate: (state, action) => {
      state.salaryDate = action.payload;
    },

    clearUserData: () => initialState,

    customerProfileInfo: (state, action) => {
      state.customerProfileInfo = action.payload;
    },

  },
});

export const {
  updateUserLogin,
  updateLeadStatus,
  updatePermissions,
  updateRoleName,
  clearUserData,
  updateLeadType,
  updateProductID,
  updateRepayDate,
  updateSalaryDate,
  customerProfileInfo
} = userSlice.actions;

export default userSlice.reducer;
