
import createApiSlice from './apiSlice';

// Create slices for two different API endpoints
export const { fetchData: fetchLoggedInUser, reducer: loggedInUserReducer } = createApiSlice('loggedInUser', '/api-crm/user');
// export const { fetchData: fetchLoggedInUser, reducer: loggedInUserReducer } = createApiSlice('loggedInUser', '/api-crm/lead/profile?leadID');
export const { fetchData: fetchQuickReportAccess, reducer: quickReportAccessReducer } = createApiSlice('quickReportAccess', (params) => {
    return `/api-crm/user/${params.userId}/management-user-report-access`;
});

export const { fetchData: fetchLoanDetails, reducer: loanDetailsReducer } = createApiSlice('loanDetails', ({leadID}) => {
   return `/api-crm/lead/${leadID}/loan-details`;
});

export const { fetchData: fetchCreditDetail, reducer: creditDetailReducer } = createApiSlice('creditDetail', ({leadID}) => {
    return `/api-crm/lead/credit-detail?leadID=${leadID}`;
});


