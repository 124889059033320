
import { createSlice } from "@reduxjs/toolkit";

const navbarSlice = createSlice({
  name: "navbar",
  initialState: {
    menus: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    updateNavBarMenuIsLoad: (state, action) => {
      state.isLoading = action.payload;
    },
    updateNavBarMenu: (state, action) => {
      state.menus = action.payload;
    },
    resetNavbarMenu: (state) => {
      state.menus = [];
    },
  },
});

export const { updateNavBarMenuIsLoad, updateNavBarMenu, resetNavbarMenu } = navbarSlice.actions;
export default navbarSlice.reducer;


