import React, { useCallback, useEffect, memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav, Placeholder } from "rsuite";

import {
  MdDashboard,
  MdSettings,
  MdOutlineStackedBarChart,
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdGroup,
  MdOutlineAutoMode,
  MdLibraryBooks,
  MdOutlineStreetview,
  MdOutlineAdsClick,
  MdAttractions,
  MdCollectionsBookmark,
  MdEditDocument,
  MdOutlineSettingsSuggest,
  MdOutlineGroups2,
  MdFileOpen,
  MdOutlinePanoramaFishEye,
} from "react-icons/md";
import { Icon } from "@rsuite/icons";
import { Link, useLocation } from "react-router-dom";
import CommonAPI from "services/CommonAPI";
import { QUICK_REPORT_BASE_URL } from "constants/report";

import {
  updateNavBarMenu,
  updateNavBarMenuIsLoad,
} from "../../redux/slices/navbarSlice";

const commonAPI = new CommonAPI();
function Navbar({ setOpenKeys, openKeys }) {
  const dispatch = useDispatch();
  const isFetchError = useRef(false);
  const sidebarNavMenu = useSelector((state) => state?.navbar);
  const location = useLocation();

  const fetchSidebarMenu = useCallback(async () => {
    if (sidebarNavMenu?.menus?.length || isFetchError.current) return;

    try {
      dispatch(updateNavBarMenuIsLoad(true));
      const response = await commonAPI.getUserPermissionAPI();
      if (!response) {
        isFetchError.current = true;
      }
      dispatch(updateNavBarMenu(response?.data));
    } catch (error) {
      console.error(error);
      isFetchError.current = true;
    } finally {
      dispatch(updateNavBarMenuIsLoad(false));
    }
  }, [sidebarNavMenu, dispatch]);

  const setOpenKeysOnLoad = useCallback((key) => {
    for(let menus of  sidebarNavMenu?.menus) {
      if(menus.subMenu) {
        for(let key in  menus.subMenu) {
          if(menus.subMenu[key].link === location.pathname) {
            setOpenKeys(menus.link);
          }
        }
      }
    }
  }, [location.pathname, sidebarNavMenu, setOpenKeys])

  useEffect(() => {
    if(location.pathname !== "/"){
      setOpenKeysOnLoad()
    }
  }, [sidebarNavMenu, location.pathname, setOpenKeysOnLoad])

  useEffect(() => {
    if (!location.pathname.includes(QUICK_REPORT_BASE_URL)) {
      fetchSidebarMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSidebarMenu]);

  const iconRegistry = {
    MdDashboard,
    MdSettings,
    MdOutlineStackedBarChart,
    MdKeyboardArrowLeft,
    MdOutlineKeyboardArrowRight,
    MdGroup,
    MdOutlineAutoMode,
    MdLibraryBooks,
    MdOutlineStreetview,
    MdOutlineAdsClick,
    MdAttractions,
    MdCollectionsBookmark,
    MdEditDocument,
    MdOutlineSettingsSuggest,
    MdOutlineGroups2,
    MdFileOpen,
    MdOutlinePanoramaFishEye,
  };

  if (sidebarNavMenu.isLoading) {
    return (
      <Placeholder.Grid
        active
        rows={20}
        columns={1}
        style={{
          opacity: 0.5,
        }}
      />
    );
  }

  return (
    <Nav
      defaultActiveKey="/"
      activeKey={location.pathname}
      onSelect={(eventKey, event) => console.log("eventKey=", eventKey)}
    >
      {sidebarNavMenu?.menus?.map((item, index) => {
        const IconComponent = iconRegistry[item.icon];
        return (
          <React.Fragment key={index}>
            {!item.hasOwnProperty("subMenu") ? (
              <Nav.Item
                as={Link}
                to={item.link}
                eventKey={item.link}
                key={index}
                icon={IconComponent ? <Icon as={IconComponent} color={item?.iconColor} /> : null}
                active={item.link === location.pathname}
                onClick={() => setOpenKeys(() => {
                  return item.link === openKeys ? null : item.link
                })}
              >
                {item.name}
              </Nav.Item>
            ) : (
              <Nav.Menu
                eventKey={item.link}
                trigger="hover"
                title={item.name}
                icon={<Icon as={IconComponent} color={item.iconColor} />}
                placement="rightStart"
                onClick={() => setOpenKeys(() => {
                  return item.link === openKeys ? null : item.link
                })}
              >
                {item.subMenu.map((data, index) => {
                  return <Nav.Item
                      as={Link}
                      to={data.link}
                      eventKey={data.link}
                      key={index}
                      icon={<Icon as={MdOutlinePanoramaFishEye} size={6} />}
                    >
                      {data.text}
                    </Nav.Item>
                })}
              </Nav.Menu>
            )}
          </React.Fragment>
        );
      })}

      <br />
      <br />
      <br />
      <br />
    </Nav>
  );
}

export default memo(Navbar);
