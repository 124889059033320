import * as Yup from "yup";

export const loginValidation = Yup.object({
  name: Yup.string().required("User name is required."),
  password: Yup.string().required("Password is required."),
});

export const otpVerifyValidation = Yup.object({
  otp: Yup.string().required("OTP is required."),
});

export const forgotPasswordValidation = Yup.object({
  userName: Yup.string().required("User name is required."),
});

export const changePasswordValidation = Yup.object({
  otp: Yup.string().required("OTP is required."),
  password: Yup.string().required("Password is required."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match.")
    .required("Confirm Password is required."),
});
